import React from 'react'
import { Enrollment } from '@clear-denver/tpc-openapi-typescript'
import { DocumentCheckCache } from '@pages/enroll/document-check'

export type EnrollmentContext = {
  state: Enrollment.Enrollment
  selectedPlan?: string | null
  affiliate?: string | null
  isPlanBundled: boolean | null
  isPromoCodeApplied: boolean | null
  ambassadorSecurityLoginActive?: boolean
  documentCheckCache?: DocumentCheckCache
  clearState: () => void
  updateState: (newState: Enrollment.Enrollment) => void
  updateDocumentCheckCache: (newCache: DocumentCheckCache) => void
  setSelectedPlan: (plan: string) => void
  setIsPlanBundled: (arg: boolean) => void
  setIsPromoCodeApplied: (arg: boolean) => void
  setAffiliate: (affiliate: string) => void
  setAmbassadorSecurityLoginActive: (
    ambassadorSecurityLoginActive: boolean
  ) => void
}

const defaultInitialState: EnrollmentContext = {
  state: {
    id: '',
    applicationId: '',
  },
  selectedPlan: null,
  isPlanBundled: null,
  isPromoCodeApplied: false,
  ambassadorSecurityLoginActive: false,
  affiliate: null,
  clearState: () => null,
  updateState: () => null,
  updateDocumentCheckCache: () => null,
  setSelectedPlan: () => null,
  setAffiliate: () => null,
  setAmbassadorSecurityLoginActive: () => false,
  setIsPlanBundled: () => null,
  setIsPromoCodeApplied: () => null,
}

const EnrollmentStateContext =
  React.createContext<EnrollmentContext>(defaultInitialState)

function EnrollmentProvider({
  children,
  initialState = defaultInitialState,
}: {
  children: React.ReactNode
  initialState?: EnrollmentContext
}) {
  const [enrollmentState, setEnrollmentState] = React.useState(
    initialState.state
  )
  const [documentCheckCache, setDocumentCheckCache] = React.useState(
    initialState.documentCheckCache
  )

  const [ambassadorSecurityLoginActive, setAmbassadorSecurityLoginActive] =
    React.useState(initialState.ambassadorSecurityLoginActive)

  const [selectedPlan, setSelectedPlan] = React.useState(
    initialState.selectedPlan
  )
  const [isPlanBundled, setIsPlanBundled] = React.useState(
    initialState.isPlanBundled
  )
  const [isPromoCodeApplied, setIsPromoCodeApplied] = React.useState(
    initialState.isPromoCodeApplied
  )

  const [affiliate, setAffiliate] = React.useState(initialState.affiliate)

  function clearState() {
    setEnrollmentState(initialState.state)
    setDocumentCheckCache(initialState.documentCheckCache)
    setSelectedPlan(initialState.selectedPlan)
    setAffiliate(initialState.affiliate)
    setAmbassadorSecurityLoginActive(initialState.ambassadorSecurityLoginActive)
  }

  return (
    <EnrollmentStateContext.Provider
      value={{
        state: enrollmentState,
        documentCheckCache,
        selectedPlan,
        updateState: setEnrollmentState,
        updateDocumentCheckCache: setDocumentCheckCache,
        clearState,
        setSelectedPlan,
        setAffiliate,
        affiliate,
        setAmbassadorSecurityLoginActive,
        ambassadorSecurityLoginActive,
        isPlanBundled,
        setIsPlanBundled,
        isPromoCodeApplied,
        setIsPromoCodeApplied,
      }}
    >
      {children}
    </EnrollmentStateContext.Provider>
  )
}

function useEnrollmentContext() {
  const context = React.useContext(EnrollmentStateContext)
  if (context === undefined) {
    throw new Error(
      'useEnrollmentContext must be used within an EnrollmentProvider'
    )
  }
  return context
}

export { EnrollmentProvider, useEnrollmentContext }
